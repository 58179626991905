import React, { FC, useState } from 'react';
import { Box, styled } from '@mui/system';
import { Alert, Button, Checkbox } from '@mui/material';
import Typography from '@mui/material/Typography';

import { CustomerWithPhone, Tariff, payCard } from '../api';
import { Loader } from '../components/loader';
import { Row } from '../components/row';
import { urlWithSBPPay } from '../helpers';

const StyledCheckbox = styled(Checkbox)`
  margin-left: -12px;
`;

interface TabPanelProps {
  tariff?: Tariff;
  customer?: CustomerWithPhone;
  handleNext: () => void;
  handleBack: VoidFunction;
}

export const UserAgreement: FC<TabPanelProps> = ({ tariff, customer, handleNext, handleBack }) => {
  const [checked, setChecked] = useState(false);
  const [checkedSecond, setCheckedSecond] = useState(false); // Второй чекбокс
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const onChangeHandler = () => {
    setChecked(!checked);
  }
  const OncheckedSecond = () => {
    setCheckedSecond(!checkedSecond);
  }

  const handleGoToPay = () => {
    const isSbpPage = urlWithSBPPay();
    if (isSbpPage) {
      handleNext();
      return;
    }

    if (!customer?.id || !tariff?.money) return;

    setLoading(true);
    
    payCard(customer.id, tariff.money)
      .then((res) => {
        console.log(res)
        if (!res.PayCard_URL) throw(new Error('Ошибка получения ссылки на оплату'));
        // eslint-disable-next-line no-restricted-globals
        location.href = res.PayCard_URL;
      })
      .catch((e) => {
        setError(e.message || 'Ошибка попробуйте позднее')
      })
      .finally(() => setLoading(false))
  }

  return (
    <Box position="relative">
      {error && <Row><Alert severity="error">{error}</Alert></Row>}
      <Typography>
        Для дальнейшей оплаты необходимо ознакомиться с пользовательским соглашением
        <Button href="https://pay.garagetesla.ru/oferta.php" target='_blank' color='secondary' size='small'>Просмотр</Button>
      </Typography>
      
     
      
      <Box>
        <StyledCheckbox checked={checked} onChange={onChangeHandler} />
        Я ознакомился с пользовательским соглашением
      </Box>
      <Box>
        <StyledCheckbox checked={checkedSecond} onChange={OncheckedSecond} />
        Я согласен на обработку персональных данных
      </Box>
      

      <Box sx={{ mb: 2 }}>
        <div>
          <Button
            variant="contained"
            onClick={handleGoToPay}
            disabled={!checked || !checkedSecond}
            
            sx={{ mt: 1, mr: 1 }}
          >
            Перейти к оплате
          </Button>
          <Button
            onClick={handleBack}
            sx={{ mt: 1, mr: 1 }}
          >
            Назад
          </Button>
        </div>
      </Box>
      {loading && <Loader />}  
    </Box>
  )
}