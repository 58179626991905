import React, { ChangeEvent, FC, ReactNode, useState } from 'react';
import InputMask from 'react-input-mask';
import { Box } from '@mui/system';
import Input from '@mui/material/Input';
import { Alert, Button, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import Typography from '@mui/material/Typography';

import { Loader } from '../components/loader';
import { findCustomer, Customer, CustomerWithPhone } from '../api';
import { Row } from '../components/row';


interface TabPanelProps {
  handleNext: (data: CustomerWithPhone) => void;
}

export const FindCustomer: FC<TabPanelProps> = ({ handleNext }) => {
  const [phone, setPhone] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState<number | null>(null);

  const clearPhone = phone.replace(/\s|_/gi, '');
  const isNextDisabled = clearPhone.length < 10;

  const searchMode = customers.length === 0;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPhone(e.target.value);
  };

  const onSearchClick = () => {
    setLoading(true);
    findCustomer(`7${clearPhone}`)
      .then((res: Customer[]) => {
        if (res.length === 0) throw(new Error(''));
        if (res.length === 1) {
          handleNext({ ...res[0], phone: clearPhone });
          setError('');
        } else if (res.length > 1) {
          setCustomers(res);
          setError('');
          res[0].id && setSelectedCustomerId(res[0].id);
        } else {
          throw(new Error(''));
        }
      })
      .catch((e) => {
        setError(e.message || 'Данный пользователь не найден, укажите правильный телефон или попробуйте поздне')
      })
      .finally(() => setLoading(false))
  }

  const onBackClick = () => {
    setPhone('');
    setSelectedCustomerId(null);
    setCustomers([]);
  }

  const onNextClick = () => {
    const selectedCustomer = customers.find(item => item.id === selectedCustomerId);
    if (!selectedCustomer) return;
    handleNext({ ...selectedCustomer, phone: clearPhone });
  }

  const handleSelectCustomer = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCustomerId(Number((event.target as HTMLInputElement).value));
  };

  return (
    <Box position="relative">
      <Typography>Введите номер телефона,который вы указывали при регистрации в lk.garagetesla.ru</Typography>
      {error && <Row><Alert severity="error">{error}</Alert></Row>}
      {searchMode ? (
        <InputMask mask="999 999 99 99" value={phone} onChange={handleChange}>
        {(inputProps) => (
          <Input
            {...inputProps}
            startAdornment={<span style={{ paddingRight: '8px' }}>+7 </span>}
            name="phone"
            placeholder="913 777 77 77"
            error={Boolean(error)}
          />
        )}
      </InputMask>) : (
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={selectedCustomerId}
          onChange={handleSelectCustomer}
        >
          {customers.map(customer => (
            <FormControlLabel value={customer.id} control={<Radio />} label={customer.name_study} />
          ))}
        </RadioGroup>
      )}

      <Box sx={{ mb: 2 }}>
        {searchMode ? (
          <div>
            <Button
              variant="contained"
              onClick={onSearchClick}
              sx={{ mt: 1, mr: 1 }}
              disabled={isNextDisabled || loading}
            >
              Поиск
            </Button>
          </div>
        ) : (
          <div>
            <Button
              variant="contained"
              onClick={onNextClick}
              sx={{ mt: 1, mr: 1 }}
              disabled={selectedCustomerId === null}
            >
              Далее
            </Button>
            <Button
              onClick={onBackClick}
              sx={{ mt: 1, mr: 1 }}
            >
              Назад
            </Button>
          </div>
        )}
      </Box>
      {loading && <Loader />}  
    </Box>
  )
}