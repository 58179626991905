import axios from 'axios';
import { customer, tariff, paySbpResponse } from './constants';

const isDevelopment = window.location.hostname === 'localhost';

const API_URL = 'https://api.garagetesla.ru/api'; //'http://149.154.70.95:81/api';

const fakeApi = <T extends any>(data: T, delay: number, fail = false): Promise<T> =>
  new Promise((resolve, reject) => {
    setTimeout(() => fail ? reject() : resolve(data), delay);
  });


export type Customer = Partial<{
  email: string[];
  id: number;
  name_parent: string;
  name_study: string;
  description?: string;
}>

export type CustomerWithPhone = Customer & { phone: string };

export const findCustomer = (phone: string, fail?: boolean) => {
  return isDevelopment ? fakeApi<Customer[]>(customer, 400, fail) : axios.post<Customer[]>(`${API_URL}/customer/?phone=${phone}`, { phone }).then(res => res.data);
}


export type Tariff ={
  id: number;
  money: string;
  visible: '0' | '1';
  description: string;
  title: string;
}

export const fetchTariff = (phone: string, fail?: boolean) => {
  return isDevelopment ? fakeApi<Tariff[]>(tariff, 400, fail) : axios.post<Tariff[]>(`${API_URL}/tariff_akadem/?phone=${phone}`, { phone }).then(res => res.data);
}


type PayDtoCard = {
  PayCard_URL: string;
}

export const payCard = (id: number, money: string, fail?: boolean) => {
  return isDevelopment ? fakeApi<PayDtoCard>({ PayCard_URL: 'http://ya.ru'}, 400, fail) : axios.post<PayDtoCard>(`${API_URL}/pay_card/?id=${id}&money=${money}`, { id, money }).then(res => res.data);
}

export type PayDtoSBP = {
  Token: string;
  email: string[];
  money: string;
  name_parent: string;
  order_id: number;
  study_name: string;
  terminal_key: string;
}

export const paySBP = (id: number, money: string, fail?: boolean) => {
  return isDevelopment ? fakeApi<PayDtoSBP>(paySbpResponse, 400, fail) : axios.post<PayDtoSBP>(`${API_URL}/pay_spb/?id=${id}&money=${money}`, { id, money }).then(res => res.data);
}